<template>
  <div id="app">
    <mainCom></mainCom>
  </div>
</template>

<script>
import mainCom from './components/mainCom.vue'

export default {
  name: 'App',
  components: {
    mainCom
  }
}
</script>

<style>
</style>
